.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-container {
  display: flex;
  width: 100vw;
  background-color: #ededed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.about-us-button {
  transition: background-color 0.3s;
}

.about-us-button:hover {
  background-color: #00d1b8;
  color: #151035;
}

.body-text-color {
  background-image: linear-gradient(#00fff2, #00ff1e);
  color: transparent;
  background-clip: text;
}

.calendar-color {
  background-image: linear-gradient(#fb00ff, #6c1dff);
  color: transparent;
  background-clip: text;
}
