/* If the screen size is 601px wide or more */
@media screen and (min-width: 601px) {
  .section-container {
    width: 60vw;
  }
}

/* If the screen size is 600px wide or less */
@media screen and (max-width: 600px) {
  .section-container {
    width: 80vw;
  }
}
