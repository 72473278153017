@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  color: #151035;
  background-color: #f5f8fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-weight: 400;
  text-align: left;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 700;
}

/* If the screen size is 601px wide or more */
@media screen and (min-width: 601px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 32px;
  }

  p {
    font-size: 18px;
  }

  p.footer {
    font-size: 14px;
  }
}

/* If the screen size is 600px wide or less */
@media screen and (max-width: 600px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }

  p.footer {
    font-size: 12px;
  }
}
